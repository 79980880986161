<template>
  <v-container grid-list-lg>
    <v-layout row wrap class="justify-center">
      <v-card color="accent" v-if="machines.length > 0" class="mb-14">
        <v-container class="myContainer mt-3 pb-0">
          <v-row cols="12">
            <v-col xl="10" lg="10" md="8" class="pt-0 pb-0">
              <span class="myGeneralCardSubTitle secondary--text">Los resultados de tu búsqueda son: </span>
              <!-- <span class="myGeneralCardTitle">Corta céspedes</span>  -->
              <!--TODO coger el valor del filtrado en vez corta cespeds -->
            </v-col>
            <v-col xl="2" lg="2" md="4" class="pt-0 pb-0">
              <v-btn text color="primary" class="pr-1" style="float: right;" @click="goToSearch">
                <span class="secondary--text">Nueva búsqueda</span>
                <v-icon right color="secondary">mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="myContainer pt-0">
          <v-row>
            <v-col cols="12" :xl="colsXL" :lg="colsLG" md="colsMD" sm="12" v-for="(item, idx) in machines" :key="idx">
              <!-- Tarjeta de cada máquina -->
              <v-card :elevation="0" :hover="true" :height="200" @click="gotoDetail(item.machineuuid)">
                <div class="d-flex">
                  <img :height="200" :width="150" v-lazy="item.imgurl" class="imageCard" />
                  <div style="text-align: start;">
                    <v-card-subtitle class="myCardSubTitle secondary--text">{{item.machinename | uppercase}}</v-card-subtitle>
                    <v-card-title class="myCardTitle" v-text="item.machinebrandname"></v-card-title>
                    <v-card-text>
                      <ul>
                        <span class="font-weight-bold secondary-text"> {{item.machineprice}} € / Día</span>
                      </ul>
                      <div>
                        <v-rating v-model="item.machinerate" color="secondary" dense readonly size="20"></v-rating>
                      </div>
                      <ul>
                        <span class="font-weight secondary--text">{{item.machineowneralias | uppercase}}</span>
                      </ul>   
                    </v-card-text>
                  </div> 
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card color="accent" class="pa-5" v-else>
        <v-row cols="12">
          <v-col xl="10" lg="10" md="8" class="pt-0 pb-0">
            <span class="myGeneralCardSubTitle secondary--text">No se han encontrado resultados para tú búsqueda</span>          
          </v-col>
          <v-col xl="2" lg="2" md="4" class="pt-0 pb-0">
            <v-btn text color="primary" class="pr-1" style="float: right;" @click="goToSearch">
              <span class="secondary--text">Nueva búsqueda</span>
              <v-icon right color="secondary">mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'MachinesList',
  data() {
    return{
      currentMachine: {},
      machines: [],
      colsXL: 3,
      colsLG: 4,
      colsMD: 6
    };
  },
  mounted() {
    this.machines = this.$store.getters.getMachinesResults
    if (typeof this.machines === 'undefined') {
      this.machines = []
      this.$store.commit('isResultsVisible', false)
    }
    if (this.machines.length === 1) {
      this.colsXL = 12
      this.colsLG = 12
      this.colsMD = 12
      this.$store.commit('isResultsVisible', false)
    } else {
      this.$store.commit('isResultsVisible', true)
    }
  },
  computed: {
    imgHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': 
          return 200
        case 'sm': 
          return 200
        case 'md': 
          return 200
        case 'lg': 
          return 200
        case 'xl': 
          return 190
      }
      return 200
    }
  },
  methods: {
    gotoDetail: function(uuid){
      this.$store.dispatch('getCurrentMachine', uuid);
      this.$router.push({ path: '/details/:id', query: { id: uuid } })
      // this.$router.push({ path: '/machinedetail'})
    },
    goToSearch() {
      this.$router.push({ path: '/' })
    },
    goToCategoryFilter() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
.myContainer {
  width: 100%;
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  margin-right: auto;
  margin-left: auto;
}
.myGeneralCardTitle {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.25rem;
  font-weight: 500;
}
.myGeneralCardSubTitle {
  padding-bottom: 0;
  font-size: 1.25rem;
  font-weight: 300;
}
.myCardTitle {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}
.myCardSubTitle {
  padding-bottom: 0;
}
ul {
  padding-left: 0;
}
.imageCard {
  object-fit: cover;
  padding: 15px;
}
</style>